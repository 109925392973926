import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@services/app.service';
import {ApiService} from '@services/api.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})

export class MenuSidebarComponent implements OnInit {
  @HostBinding('class') classes: string = BASE_CLASSES;
  public ui: Observable<UiState>;
  public userCompany='';
  public menu:any = [];
  public extendableMenu:any = [];
  constructor (public appService: AppService, private store: Store<AppState>, private apiservice : ApiService) {}
  
  ngOnInit() {
    this.ui = this.store.select('ui');
    this.ui.subscribe((state: UiState) => {
      this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
    });
    this.getAllData();
  }

  convertLinksToLowerCase(obj:any[]) {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && value !== null) {
        this.convertLinksToLowerCase(value);
      } else if (key === 'Link') {
        obj[key] = value.toLowerCase();
      }
    }
  }
  
  getAllData(){
    let userData=JSON.parse(localStorage.getItem('personalDetails'));
    this.userCompany = (userData.FName?userData.FName.toUpperCase():'')+(userData.LName?' '+userData.LName.toUpperCase():'');
    this.menu = JSON.parse(localStorage.getItem('adminMenu'));
    console.log("left Bar Menu", this.menu)
    // let params = config['getAdminMenuMappingsData'];
    // this.apiservice.getRequest(params).subscribe((data: any) => {
    //   this.convertLinksToLowerCase(data);
    //   if (data.length > 0) {
    //     this.menu = data;
    //   }
    // }, (error) => {
    //   console.log(error);
    // });
  }
}

// export const MENU = 
// [ 
//   {
//     "Id": "1",
//     "MenuName": "Dashboard",
//     "Link": "#",
//     "Icon": "home",
//     "HasChild": 1,
//     "ChildMenu": [
//       {
//         "Id": null,
//         "MenuName": "My Dashboard",
//         "Link": "/dashboard/index",
//         "Icon": "minus",
//         "HasChild": 0,
//         "ChildMenu": null
//       }
//     ]
//   },
//   {
//     "Id": "2",
//     "MenuName": "Players",
//     "Link": "/users/searchplayer",
//     "Icon": "users",
//     "HasChild": 0,
//     "ChildMenu": []
//   },
//   {
//     "Id": "3",
//     "MenuName": "Withdraw",
//     "Link": "#",
//     "Icon": "bar-chart",
//     "HasChild": 1,
//     "ChildMenu": [
//       {
//         "Id": null,
//         "MenuName": "WithDrawal request",
//         "Link": "/users/withdrawalrequest",
//         "Icon": "minus",
//         "HasChild": 0,
//         "ChildMenu": null
//       }
//     ]
//   }
// ];