import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@common/main/main.component';
import {LoginComponent} from '@auth/login/login.component';
import {RegisterComponent} from '@auth/register/register.component';
import {AuthGuard} from '@guards/auth.guard';
import {RoleauthGuard} from '@guards/role-auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@auth/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@auth/recover-password/recover-password.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'product',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/product/product.module').then(m => m.ProductModule)
            },
            {
                path: 'common-setup',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/common-setup/common-setup.module').then(m => m.CommonSetupModule)
            },
            {
                path: 'walletmanagement',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/wallet/wallet.module').then(m => m.WalletModule)
            },
            {
                path: 'usermanagement',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/user/user.module').then(m => m.UserModule)
            },
            {
                path: 'commissionsetting',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/commition-setting/commition-setting.module').then(m => m.CommitionSettingModule)
            },
            {
                path: 'paymentsetting',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/account-management/account-management.module').then(m => m.AccountManagementModule)
            },
            {
                path: 'userhomepage',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/user-home-content/user-home-content.module').then(m => m.UserHomeContentModule)
            },
            {
                path: 'users',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/users/users.module').then(m => m.UsersModule)
            },
            // {
            //     path: 'bank',
            //     canActivate: [RoleauthGuard],
            //     canActivateChild: [RoleauthGuard],
            //     loadChildren: () => import('@modules/bank/bank.module').then(m => m.BankModule)
            // },
            // {
            //     path: 'report',
            //     canActivate: [RoleauthGuard],
            //     canActivateChild: [RoleauthGuard],
            //     loadChildren: () => import('@modules/report/report.module').then(m => m.ReportModule)
            // },
            // {
            //     path: 'creator',
            //     canActivate: [RoleauthGuard],
            //     canActivateChild: [RoleauthGuard],
            //     loadChildren: () => import('@modules/creator/creator.module').then(m => m.CreatorModule)
            // },
            // {
            //     path: 'promo',
            //     canActivate: [RoleauthGuard],
            //     canActivateChild: [RoleauthGuard],
            //     loadChildren: () => import('@modules/promo/promo.module').then(m => m.PromoModule)
            // }
            {
                path: 'leads',
                canActivate: [RoleauthGuard],
                canActivateChild: [RoleauthGuard],
                loadChildren: () => import('@modules/leads/leads.module').then(m => m.LeadsModule)
            },
        ]
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'register',
        component: RegisterComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [NonAuthGuard]
    },
    {
        path: 'change-password',
        component: RecoverPasswordComponent,
        canActivate: [AuthGuard]
    },
    {path: '**', redirectTo: ''}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
