import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { config } from './config';

@Injectable({
  providedIn: 'root'
})

export class AppService {
  public user: any = null;
  private authLoader = new BehaviorSubject<boolean>(false);
  
  constructor(private router: Router, private toastr: ToastrService, private apiservice :ApiService) {}
  
  async loginByOTP(apiEndpoint:string,{email, password, otpnumber, secretkey},loaderKey:string) {
    try {
      let param = {
        "username":email,
        "password":password,
        "OTPNumber":otpnumber,
        "SecretKey":secretkey
      }
      this.authLoader.next(true);
      this.apiservice.sendRequest(apiEndpoint,param,loaderKey).subscribe((response: any) => {
        if(response.ErrorCode==1){
          let personalDetails = {FName: response.FName, LName: response.LName, LoginToken: response.LoginToken, UserId: response.UserId, RoleCode:response.RoleCode};
          localStorage.setItem('personalDetails', JSON.stringify(personalDetails));
          let params = config['getAdminMenuMappingsData'];
          let data = response.MenuItems[0];
            let defaultPath='';
            if (data.length > 0) {
              this.convertLinksToLowerCase(data);
              localStorage.setItem('adminMenu', JSON.stringify(data));
              if(data[0].AppUrl!='#')
              {
                defaultPath = (data[0].AppUrl).toLowerCase();
              }
              else
              {
                defaultPath = (data[0].ChildMenu[0].AppUrl).toLowerCase();
              }
              this.router.navigate([defaultPath]);
            }
            else
            {
              this.toastr.error('','Please Try Later',{positionClass: 'toast-top-center'});
            }
        }
        else{
          this.toastr.error('',response.ErrorMessage,{positionClass: 'toast-top-center'});
          localStorage.removeItem('personalDetails');
          localStorage.removeItem('adminMenu');
          localStorage.removeItem('userSites');
        }
      })
    } catch (error) {
      this.authLoader.next(false);
      console.log(error);
      this.toastr.error(error.message);
    }
  }
  
  convertLinksToLowerCase(obj:any[]) {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && value !== null) {
        this.convertLinksToLowerCase(value);
      } else if (key === 'AppUrl') {
        obj[key] = value.toLowerCase();
      }
    }
  }
  
  logout() {
    localStorage.removeItem('personalDetails');
    localStorage.removeItem('adminMenu');
    localStorage.removeItem('userSites');
    sessionStorage.clear();
    this.user = null;
  }
}
