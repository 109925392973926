import {Component , OnInit} from '@angular/core';
// import { SignalService } from '@services/signal.service';
// import { ToastrService } from 'ngx-toastr';
import * as alertify from 'alertifyjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  // constructor(private signalR:SignalService,private toast:ToastrService){}
  audio=new Audio();
  ngOnInit(): void {
    if(localStorage.getItem('userSites')){
      let userSites = JSON.parse(localStorage.getItem('userSites'));
      if(!sessionStorage.getItem('selectedSite')){
        sessionStorage.setItem('selectedSite',userSites[0].Code);
        sessionStorage.setItem('chosenSite',userSites[0].Name);
        sessionStorage.setItem('WalChosen',userSites[0].Wallets[0].Id);
        sessionStorage.setItem('WalList',JSON.stringify(userSites[0].Wallets));
        sessionStorage.setItem('WalName',userSites[0].Wallets[0].Name+' - '+userSites.Wallets[0].Code);
      }
    }
    // this.recivedMsg();
  }

  // recivedMsg(){
  //   this.signalR.messageReceived$.subscribe((data)=>{
  //     // console.log(data);
  //     alertify.set('notifier','delay', 10);
  //     alertify.success('<span class="alertTitle">'+data.NotificationType+'</span>'+'<div style="line-height:1.4 !important"><span class="alertText">'+data.message+'</span></div>',
  //     'success', 5, function(){  console.log('dismissed'); });
  //     this.audio.src = '/assets/music/notimu.mp3';
  //     this.audio.load();
  //     this.audio.play();
  //   });
  // }
}