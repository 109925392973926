import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
// import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ApiservicesService {

  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private router: Router) { }

  private getHeaders(): HttpHeaders {
    const personalData = JSON.parse(localStorage.getItem('personalDetails'));
    const token = personalData ? personalData['LoginToken'] : '';
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`
    });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      if (error.error && error.error.code === 'token_not_valid') {
        // Redirect to login page if token is invalid or expired
        this.router.navigate(['login']);
      } else {
        // Handle other types of 401 errors
        console.error('Unauthorized: Invalid token');
        return throwError('Unauthorized: Invalid token');
      }
    } else {
      console.error('API Error:', error);
      return throwError('An error occurred, please try again later.');
    }
  }

  //..............admin change password .................//
  adminChangepass(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}admin-change-password/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  //...............................Dashboard ..........................//
  getDashboradData(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}dashboard/`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }

  // ..................category page........................//

  getCategory(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}categories/`, { headers: this.getHeaders() })
      .pipe(
        catchError(this.handleError)
      );
  }
  getCategoryRequestById(id: any): Observable<any> {
    // Constructing HTTP parameters
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${this.baseUrl}categories/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  addCategory(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}categories/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  editCategory(id: any, user: any): Observable<any> {
    return this.http.put(`${this.baseUrl}categories/` + id + "/", user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  deleteCategoryByID(id: any, user: any): Observable<any> {
    return this.http.put(`${this.baseUrl}categories/` + id + "/", user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  // ..................product page.........................//

  getProduct(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}products/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  getProductRequestById(id: any): Observable<any> {
    // Constructing HTTP parameters
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${this.baseUrl}products/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  //upload payment image
  uploadImage(image: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}fileuploadurl/`, image,);
  }

  addProduct(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}products/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  editProduct(id: any, user: any): Observable<any> {
    return this.http.put(`${this.baseUrl}products/` + id + "/", user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  deleteProductByID(id: any, user: any): Observable<any> {
    return this.http.put(`${this.baseUrl}products/` + id + "/", user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  // ..................left Panel.........................//

  getLeftPanel(role_id?: any): Observable<any> {
    return this.http.get(`${this.baseUrl}getMenuMappings?role_id=${role_id}/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  postLeftPanel(user: any, role_id?: any): Observable<any> {
    return this.http.post(`${this.baseUrl}getMenuMappings?role_id=${role_id}/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  editByIdLeftPanel(id: any, user: any, role_id?: any): Observable<any> {
    return this.http.put(`${this.baseUrl}getMenuMappings?role_id=${role_id}/` + id + "/", user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  /***************************Role Master****************************/
  getroledata(): Observable<any> {
    return this.http.get(`${this.baseUrl}roles/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  createroleinfo(user: any): Observable<any> {
    return this.http.post(`${this.baseUrl}roles/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  editroleinfo(data, id: any): Observable<any> {
    return this.http.put(`${this.baseUrl}roles/${id}/`, data, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  deleterole(id: any): Observable<any> {
    return this.http.delete(`${this.baseUrl}roles/${id}/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }


  // ..................Wallet Page........................//

  getBalanceWalletList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}balance-wallet/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  getBalanceWalletListById(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}balance-wallet/${id}/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  editBalanceWalletList(id: any, formData: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}balance-wallet/${id}/`, formData, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  // ..................Recharge Page........................//

  getRechargeWalletList(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}recharge-wallet/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  getRechargeWalletListById(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}recharge-wallet/${id}/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  editRechargeWalletList(id: any, formData: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}recharge-wallet/${id}/`, formData, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  // ...................User Transaction .....................//

  getUserTransaction(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}transaction-list/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }


  // ...................Reacharge Request Page .....................//

  getRechargeRequest(): Observable<any> {
    const params = new HttpParams().set("message", "Pending")
    return this.http.get<any>(`${this.baseUrl}recharge-request/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  getRechargeRequestById(id: any): Observable<any> {
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${this.baseUrl}recharge-request/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  putRechargeRequestById(id: any, data): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}recharge-request/${id}/`, data, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  //.................... recharge History ............................//
  getRechargeHistory(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}recharge-request/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  //.................... withdrawal History ............................//
  getWithdrawalHistory(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}withdrawal-request/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  // ...................Withdrawal Request Page .....................//

  getWithdrawalRequest(): Observable<any> {
    const params = new HttpParams().set("message", "Pending")
    return this.http.get<any>(`${this.baseUrl}withdrawal-request/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  getWithdrawalRequestById(id: any): Observable<any> {
    // const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${this.baseUrl}withdrawal-request/${id}/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  putWithdrawalRequestById(id: any, data): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}withdrawal-request/${id}/`, data, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }


  // ................... All User List ...........................//

  getAllUser(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}user/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  getUserByID(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}user/?user_id=${id}`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  editUserByID(id: any, user: any): Observable<any> {
    return this.http.patch<any>(`${this.baseUrl}user/${id}/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  getUserBalanceWalletById(id: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.get<any>(`${this.baseUrl}balance-wallet/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  getUserRechargeWalletById(id: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.get<any>(`${this.baseUrl}recharge-wallet/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  getUserTransactionList(id: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.get<any>(`${this.baseUrl}user-transaction-list/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  getUserFundLog(id: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.get<any>(`${this.baseUrl}user-fund-list/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }
  getUserPayoutList(id: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.get<any>(`${this.baseUrl}user-payout-list/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  getUserNotificationList(id: any): Observable<any> {
    const params = new HttpParams().set('pk', id);
    return this.http.get<any>(`${this.baseUrl}user-notification/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }
  getUserNotificationListById(id: any): Observable<any> {
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${this.baseUrl}user-notification/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }
  addUserNotification(id: any, user: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.post<any>(`${this.baseUrl}user-notification/`, user, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }
  editUserNotificationByID(id: any, user: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}user-notification/${id}/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  getUserTeamlist(id: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.get<any>(`${this.baseUrl}user-team-list/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }
  getUserInvestment(id: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.get<any>(`${this.baseUrl}order/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }
  changeUserPassword(id: any, user: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.post<any>(`${this.baseUrl}change-password/`, user, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }
  getAdminAcountDetails(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}admin-account-detail/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  getAdminAcountDetailsByID(id: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}admin-account-detail/${id}/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  addAdminAcountDetails(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}admin-account-detail/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  changeAdminAcountDetails(id: any, user: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}admin-account-detail/${id}/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  getUserAcountDetails(id: any): Observable<any> {
    const params = new HttpParams().set('user_id', id);
    return this.http.get<any>(`${this.baseUrl}account-detail/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }
  changeUserAcountDetails(id: any, user: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}account-detail/${id}/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  getDropDownData(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}bonus-type/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  getAllReferalcommition(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}referral-commission/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  postAllReferalcommition(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}referral-commission/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  putAllReferalcommition(user: any): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}referral-commission/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  putBonusAmount(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}bonus/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }
  getBonusAmount(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}bonus/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  //...........................user home page ..............................//
  gethomeContent(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}home-slider/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  gethomeContentByID(id: any): Observable<any> {
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${this.baseUrl}home-slider/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  posthomeContent(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}home-slider/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  puthomeContent(id: any, user: any): Observable<any> {
    // const params = new HttpParams().set('id', id);
    return this.http.put<any>(`${this.baseUrl}home-slider/${id}/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  //.....................offers.......................//

  getAllOffers(): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}new-offers/`, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  getAllOffersByID(id: any): Observable<any> {
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(`${this.baseUrl}new-offers/`, { headers: this.getHeaders(), params: params }).pipe(
      catchError(this.handleError)
    );
  }

  postAllOffers(user: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}new-offers/`, user, { headers: this.getHeaders() }).pipe(
      catchError(this.handleError)
    );
  }

  putAllOffers(id: any, user: any): Observable<any> {
    // const params = new HttpParams().set('id', id);
    return this.http.put<any>(`${this.baseUrl}new-offers/${id}/`,user, { headers : this.getHeaders()}).pipe(
      catchError(this.handleError)
    );
  }

}